// ==============================
// Custom style
// 自定义样式
// ==============================

$color_1: #f99b01;

.db-card {
	margin: 2rem 3rem;
	background: #fafafa;
	border-radius: 8px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), 0 0 1px rgba(0, 0, 0, 0.25);
}
.db-card-subject {
	display: flex;
	align-items: flex-start;
	line-height: 1.6;
	padding: 12px;
	position: relative;
}
.dark {
	.db-card {
		background: #252627;
	}
}
.db-card-content {
	flex: 1 1 auto;
}
.db-card-post {
	width: 96px;
	margin-right: 15px;
	display: flex;
	flex: 0 0 auto;
	img {
		width: 96px !important;
		height: 96px !important;
		border-radius: 4px;
		-o-object-fit: cover;
		object-fit: cover;
	}
}
.db-card-title {
	margin-bottom: 5px;
	font-size: 18px;
	a {
		text-decoration: none !important;
	}
}
.db-card-abstract {
	font-size: 14px;
	overflow: hidden;
	max-height: 2.5rem;
}
.db-card-comment {
	font-size: 14px;
	overflow: hidden;
	max-height: 3rem;
}
.db-card-cate {
	position: absolute;
	top: 0;
	right: 0;
	background: #f99b01;
	padding: 1px 8px;
	font-size: small;
	font-style: italic;
	border-radius: 0 8px 0 8px;
	text-transform: capitalize;
}


.rating {
	margin: 0 0 5px;
	font-size: 13px;
	line-height: 1;
	display: flex;
	align-items: center;
	.allstardark {
		position: relative;
		color: $color_1;
		height: 16px;
		width: 80px;
		background-size: auto 100%;
		margin-right: 8px;
		background-repeat: repeat;
		background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiPjxwYXRoIGQ9Ik05MDguMSAzNTMuMWwtMjUzLjktMzYuOUw1NDAuNyA4Ni4xYy0zLjEtNi4zLTguMi0xMS40LTE0LjUtMTQuNS0xNS44LTcuOC0zNS0xLjMtNDIuOSAxNC41TDM2OS44IDMxNi4ybC0yNTMuOSAzNi45Yy03IDEtMTMuNCA0LjMtMTguMyA5LjMtMTIuMyAxMi43LTEyLjEgMzIuOS42IDQ1LjNsMTgzLjcgMTc5LjEtNDMuNCAyNTIuOWMtMS4yIDYuOS0uMSAxNC4xIDMuMiAyMC4zIDguMiAxNS42IDI3LjYgMjEuNyA0My4yIDEzLjRMNTEyIDc1NGwyMjcuMSAxMTkuNGM2LjIgMy4zIDEzLjQgNC40IDIwLjMgMy4yIDE3LjQtMyAyOS4xLTE5LjUgMjYuMS0zNi45bC00My40LTI1Mi45IDE4My43LTE3OS4xYzUtNC45IDguMy0xMS4zIDkuMy0xOC4zIDIuNy0xNy41LTkuNS0zMy43LTI3LTM2LjN6TTY2NC44IDU2MS42bDM2LjEgMjEwLjNMNTEyIDY3Mi43IDMyMy4xIDc3MmwzNi4xLTIxMC4zLTE1Mi44LTE0OUw0MTcuNiAzODIgNTEyIDE5MC43IDYwNi40IDM4MmwyMTEuMiAzMC43LTE1Mi44IDE0OC45eiIgZmlsbD0iI2Y5OWIwMSIvPjwvc3ZnPg==);
	}
	.allstarlight {
		position: absolute;
		left: 0;
		color: $color_1;
		height: 16px;
		overflow: hidden;
		background-size: auto 100%;
		background-repeat: repeat;
		background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiPjxwYXRoIGQ9Ik05MDguMSAzNTMuMWwtMjUzLjktMzYuOUw1NDAuNyA4Ni4xYy0zLjEtNi4zLTguMi0xMS40LTE0LjUtMTQuNS0xNS44LTcuOC0zNS0xLjMtNDIuOSAxNC41TDM2OS44IDMxNi4ybC0yNTMuOSAzNi45Yy03IDEtMTMuNCA0LjMtMTguMyA5LjMtMTIuMyAxMi43LTEyLjEgMzIuOS42IDQ1LjNsMTgzLjcgMTc5LjEtNDMuNCAyNTIuOWMtMS4yIDYuOS0uMSAxNC4xIDMuMiAyMC4zIDguMiAxNS42IDI3LjYgMjEuNyA0My4yIDEzLjRMNTEyIDc1NGwyMjcuMSAxMTkuNGM2LjIgMy4zIDEzLjQgNC40IDIwLjMgMy4yIDE3LjQtMyAyOS4xLTE5LjUgMjYuMS0zNi45bC00My40LTI1Mi45IDE4My43LTE3OS4xYzUtNC45IDguMy0xMS4zIDkuMy0xOC4zIDIuNy0xNy41LTkuNS0zMy43LTI3LTM2LjN6IiBmaWxsPSIjZjk5YjAxIi8+PC9zdmc+);
	}
}
@media (max-width:550px) {
	.db-card {
		margin: 0.8rem 1rem;
	}
	.db-card-comment {
		display: none;
	}
}


// Custom css
p {
  display: block;
  margin-block-start: 2em;
  margin-block-end: 2em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}


body {
  background-color: #fefefe;
  color: #161209;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

img {
  border-radius: 5px;
  border-style: none;
  display: block;
  margin: 0 auto;
}

.content iframe {
  background: #ccc;
  padding: 0.4rem;
  border-radius: 1rem;
  display: block; /* ブロック要素にすることでmarginを適用 */
  margin: 0 auto 1rem; /* 上下のマージンを指定しつつ中央揃え */
}

.home.posts .summary .single-title {
  font-size: 1.4rem;
  line-height: 140%;
  margin: 0.4rem 0;
}

.misskey-note {
  display: block; /* ブロック要素にすることでmarginを適用 */
  margin: 0 auto 1rem; /* 上下のマージンを指定しつつ中央揃え */
}
