// ==============================
// Override Variables
// 覆盖变量
// ==============================

@import url('https://static.zeoseven.com/zsft/94/main/result.css');

$global-font-family: "LXGW WenKai Mono GB", system-ui, -apple-system, Segoe UI, Roboto, Emoji, Helvetica, Arial, sans-serif;
$global-font-size: 104%;

